import type { Ref } from "vue"
import type { SubscriptionBoxOrder } from "~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService"

import { subscriptionBoxOrderTitle } from "~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService"

export function useOrderInfoView(order: Ref<SubscriptionBoxOrder | null>) {
  const orderTitle = computed(() => {
    if (order.value != null) {
      return subscriptionBoxOrderTitle(order.value)
    } else {
      return undefined
    }
  })

  const orderStatusDescription = computed(() => {
    if (order.value != null) {
      return statusDescription(order.value.status)
    } else {
      return undefined
    }
  })

  const orderTrackingUrl = computed(() => {
    if (order.value?.trackingCode) {
      return `/minha-conta/rastreamento/${order.value.id}`
    } else {
      return undefined
    }
  })

  const orderFeedbackUrl = computed(() => {
    if (order.value?.status == "DELIVERED") {
      return `/minha-conta/box/${order.value.id}/feedback`
    } else {
      return undefined
    }
  })

  const orderNfeUrl = computed(() => {
    if (order.value?.nfeAccessKey) {
      return `https://customer-api.boxmagenta.com.br/v1/magenta/subscription-box-order/${order.value.nfeAccessKey}/danfe`
    } else {
      return undefined
    }
  })

  const enableNfeButton = computed(() => {
    if (orderNfeUrl.value != null && order.value?.status == "DELIVERED") {
      return true
    } else {
      return false
    }
  })

  const finalStepStatus = computed(() => {
    if (order.value != null) {
      switch (order.value.status) {
        case "RETURNED":
          return "Retornada"
        case "MISPLACED":
          return "Extraviada"
        default:
          return "Entregue"
      }
    } else {
      return undefined
    }
  })

  const finalStepDate = computed(() => {
    if (order.value != null) {
      switch (order.value.status) {
        case "RETURNED":
        case "MISPLACED":
          return undefined
        default:
          return order.value.deliveredAt
      }
    } else {
      return undefined
    }
  })

  return {
    orderTitle,
    orderStatusDescription,
    orderTrackingUrl,
    orderFeedbackUrl,
    orderNfeUrl,
    enableNfeButton,
    finalStepStatus,
    finalStepDate,
  }
}

function statusDescription(status: string): string {
  switch (status) {
    case "CANCELED":
      return `Essa box foi <span class="magenta-text">cancelada</span>`

    case "NEW":
      return `Essa box acaba de ser <span class="magenta-text">encomendada</span>`

    case "IN_PRODUCTION":
      return `Essa box está <span class="magenta-text">em produção</span>`

    case "PRODUCT_SELECTION_FAILED":
      return `Essa box está <span class="magenta-text">em produção</span>`

    case "PRODUCTS_SELECTED":
      return `Essa box está <span class="magenta-text">em produção</span>`

    case "READY_TO_POST":
      return `Essa box está <span class="magenta-text">pronta para envio</span>`

    case "IN_TRANSIT":
      return `Essa box já foi <span class="magenta-text">enviada</span>`

    case "AWAITING_CUSTOMER_PICKUP":
      return `Essa box não pode ser entregue no endereço cadastrado, mas está
              <span class="magenta-text">disponível para coleta</span>
              na agência mais próxima`

    case "DELIVERED":
      return `Essa box já foi <span class="magenta-text">entregue</span>`

    case "RETURNED":
      return `Essa box foi
              <span class="magenta-text">retornada</span>
              pela transportadora.<br/>
              Entraremos em contato para resolver o problema.`

    case "MISPLACED":
      return `Essa box foi
              <span class="magenta-text">extraviada</span><br/>
              Entraremos em contato para resolver o problema.`

    default:
      return ""
  }
}
